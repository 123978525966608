import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/kenneth/Sites/typebrigade/typebrigade.com/src/components/TemplateBlogPost.jsx";
import Schedule from "../components/Schedule";
import Speakers from "../components/Speakers";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Type Brigade №50`}</h1>
    <p>{`On March 30, 2020, we’re hosting a special event one-day Type Brigade event! This lines up with Type Brigade №50, and celebrates our 5th year as a speaker series.`}</p>
    <p>{`This is the only event we’re planning for 2020, so we hope to see you there.`}</p>
    <p>{`You’ll see six of incredible speakers delivering new talks, with more surprises to come.`}</p>
    <p><tito-widget event="typebrigade/50"></tito-widget></p>
    <style>
      {`
.tito-submit, .tito-submit:hover {
  background: #EC008C !important;
  border-color: #EC008C !important
}
.tito-badge-link {
  display: none;
}
`}
    </style>
    <h2>{`Schedule`}</h2>
    <Schedule mdxType="Schedule" />
    <h2>{`Speakers`}</h2>
    <Speakers mdxType="Speakers" />
    <p>{`Thanks for being part of Type Brigade over these last 5 years, however long it’s been for you, and we hope to see you at this special event.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      